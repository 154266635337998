
<template>
  <a-card :bordered="false">
    <div class="page-header-index-wide page-header-wrapper-grid-content-main" style="text-align: center">
      <div class="userinfo">
        <table class="userinfo_table">
          <tr>
            <td colspan="4" style="text-align: left;font-size:20px">
              <label style="font-weight: bold" id="orgInfoName">{{ orgInfo.name }}</label
              >：<label id="orgInfoLevelinfo" style="font-weight: bold; color: red">{{ orgInfo.levelinfo }}</label>
            </td>
          </tr>
          <tr>
            <!-- <td style="width: 200px">&nbsp;</td> -->
            <td></td>
            <td style="height: 150px; vertical-align: bottom;padding-bottom:30px;font-size:18px">请按要求上传以下内容进行资质认证</td>
            <td></td>
          </tr>
          <tr>
            <td style="padding-left:22px">营业执照</td>
            <td style="padding-left:22px">代理合同（首页）</td>
            <td style="padding-left:22px">代理合同（末页）</td>
          </tr>
          <tr>
            <td>
              <a-upload
                name="file"
                :max-count="1"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :customRequest="customRequest1"
              >
                <img id="pic1" src="" width="100px" height="100px" alt="avatar" />
              </a-upload>
              <br /><a @click="showbig(orgInfo.pic1)">查看大图1</a>
            </td>
            <td style="margintop: 10px;">
              <a-upload
                name="file"
                :max-count="1"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :customRequest="customRequest2"
              >
                <img id="pic2" src="" width="100px" height="100px" alt="avatar" />
              </a-upload>
              <br /><a @click="showbig(orgInfo.pic2)">查看大图2</a>
            </td>
            <td>
              <a-upload
                name="file"
                :max-count="1"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :customRequest="customRequest3"
              >
                <img id="pic3" src="" width="100px" height="100px" alt="avatar" />
              </a-upload>
              <br /><a @click="showbig(orgInfo.pic3)">查看大图3</a>
            </td>
          </tr>
          <tr>
            <td style="width: 200px; height: 80px">&nbsp;</td>
            <td colspan="3">
              <a-button
                id="but-rz"
                type="primary"
                @click="handleAdd()"
                >认证</a-button
              >
            </td>
          </tr>
        </table>

        <a-modal
          key="updateinfo"
          title="更新信息"
          width="auto"
          :visible="visible_showbig"
          :confirmLoading="confirmLoading"
          :destroyOnClose="true"
          :closable="true"
          @cancel="showbigCancel"
          :centered="true"
          :mask="true"
          :footer="null"
          :maskClosable="false"
          :body-style="{ minheight: '200px' }"
          style="min-width: 300px"
        >
          <div style="min-height: 200px; overflow-y: auto">
            <img src="" id="bigpic" />
          </div>
        </a-modal>
      </div>
    </div>
  </a-card>
</template>

<script>
import { message } from 'ant-design-vue'
import { UploadChangeParam, UploadProps } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'
import QRCode from 'qrcodejs2'
import { findListVehicle, getUserInfo, getOrg, leveljudge, saveRole, updateUser, updateUserMobile } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { getSmsCaptcha } from '@/api/login'
import { put } from '@/utils/upload'

export default {
  name: 'UserMenu',
  components: {
    LoadingOutlined,
    PlusOutlined,
  },
  data() {
    return {
      orgInfo: {},
      updateUserInfoVo: {},
      visible_showbig: false,
      confirmLoading: false,
      updateVo: {},
      pic1: null,
      pic2: null,
      pic3: null,
    }
  },
  computed: {
    headers() {
      let token = Vue.ls.get(ACCESS_TOKEN)
      return {
        token: token,
      }
    },
  },
  methods: {
    customRequest1(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.orgInfo.pic1 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic1')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest2(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.orgInfo.pic2 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic2')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    customRequest3(action) {
      let that = this
      const file = action.file
      put(
        new Date().valueOf() + parseInt(Math.random() * 10000) + file.name.substring(file.name.lastIndexOf('.')),
        file
      )
        .then((result) => {
          let fileName = result.name
          this.orgInfo.pic3 = fileName
          let url = '/service_provider/upload/getPic?fileName=' + fileName
          that.getimgblob(url, 'pic3')
        })
        .catch((e) => {
          console.info(e)
        })
    },
    async getimgblob(url, id) {
      let token = Vue.ls.get(ACCESS_TOKEN)
      let request = new XMLHttpRequest()
      request.responseType = 'blob'
      request.open('get', url, true)
      // 携带请求头
      request.setRequestHeader('token', token)
      request.onreadystatechange = (e) => {
        if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
          document.getElementById(id).src = URL.createObjectURL(request.response)
        }
      }
      request.send(null)
    },
    handleAdd: function () {
      let that = this
      if (this.orgInfo.pic1 == null || this.orgInfo.pic2 == null || this.orgInfo.pic3 == null) {
        this.$message.error('请按要求上传资质图片')
        return false
      }
      leveljudge(this.orgInfo).then((res) => {
        console.info('---')
        that.orgInfo.level=1
        that.orgInfo.levelinfo = "待审核"
        document.getElementById('but-rz').style.display = 'none' // 隐藏选择的元素
        document.getElementById('orgInfoLevelinfo').innerText = '待审核' // 隐藏选择的元素

      })
    },
    showbig: function (fileName) {

      if(fileName!=null){
        this.visible_showbig = true
        let url = '/service_provider/upload/getPic?fileName=' + fileName
        this.getimgblob(url, 'bigpic')
      }

    },
    showbigCancel: function () {
      this.visible_showbig = false
    },
  },
  mounted: function () {
    let that = this
    getOrg().then((res) => {
      that.orgInfo.id = res.result.id
      that.orgInfo.name = res.result.name
      that.orgInfo.level = res.result.level
      if (that.orgInfo.level == null || that.orgInfo.level == 0) {
        that.orgInfo.levelinfo = '未认证'
      } else if (that.orgInfo.level == 1) {
        that.orgInfo.levelinfo = '待审核'
      } else if (that.orgInfo.level == 2) {
        that.orgInfo.levelinfo = '驳回'
      } else if (that.orgInfo.level == 3) {
        that.orgInfo.levelinfo = '一级代理'
      } else if (that.orgInfo.level == 4) {
        that.orgInfo.levelinfo = '二级代理'
      }
      if(res.result.pic1!=null){
        that.orgInfo.pic1 = res.result.pic1
        let url = '/service_provider/upload/getPic?fileName=' + res.result.pic1
        this.getimgblob(url, 'pic1')
      }else{
        let url = '/service_provider/upload/getPic?fileName=file/jiahao.png'
        this.getimgblob(url, 'pic1')
      }

      if(res.result.pic2!=null){
        that.orgInfo.pic2 = res.result.pic2
        let url = '/service_provider/upload/getPic?fileName=' + res.result.pic2
        this.getimgblob(url, 'pic2')
      }else{
        let url = '/service_provider/upload/getPic?fileName=file/jiahao.png'
        this.getimgblob(url, 'pic2')
      }
      if(res.result.pic3!=null){
        that.orgInfo.pic3 = res.result.pic3
        let url = '/service_provider/upload/getPic?fileName=' + res.result.pic3
        this.getimgblob(url, 'pic3')
      }else{
        let url = '/service_provider/upload/getPic?fileName=file/jiahao.png'
        this.getimgblob(url, 'pic3')
      }


      document.getElementById('orgInfoName').innerText = that.orgInfo.name
      document.getElementById('orgInfoLevelinfo').innerText = that.orgInfo.levelinfo
      if (that.orgInfo.level == null || that.orgInfo.level == 0 || that.orgInfo.level == 2) {

      } else {
        document.getElementById('but-rz').style.display = 'none' // 隐藏选择的元素
      }
    })
  },
}
</script>
<style lang="less" scoped>
.userinfo {
  text-align: center;
  width: 800px;
  height: 500px;
  border: 1px solid white;
  background: white;
  span {
    display: inline-block;
    width: 104px;
    text-align: left;
    color: blue;
  }
}
.userinfo_table {
  width: 800px;
  height: 300px;
}
.avatar-uploader > .ant-upload {
  width: 104px;
  height: 104px;
  display: inline-block;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.table_updateuser td {
  height: 40px;
}
.table_updateuser tr {
  height: 60px !important;
  line-height: 60px;
  > td:nth-child(1) {
    width: 70px;
    color: blue;
  }
  /deep/ .ant-input {
    width: 86%;
    height: 32px;
    line-height: 32px;
  }
}
/deep/ .ant-input-affix-wrapper {
  line-height: 32px;
}
#bigpic {
  max-width: 500px;
  max-height: 500px;
}
/deep/ .avatar-uploader {
  margin-top: 10px;
  .ant-upload{
    line-height: 100% !important;
  }
}
</style>
